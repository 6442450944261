<template>
    <Component
        :is="componentInstance"
        v-bind="$attrs"
        :get-data-vuex="getCities"
        :placeholder="placeholder"
        :multiple="multiple"
        :value="value"
        class="white"
        @[event]="$emit(event, $event)">
    </Component>
</template>
<script>
  import { UsersSelect } from "@core/mixins/select/usersSelect";
  import Select from "@/components/Common/Select/Select.vue";

  export default {
    name: "ExpenseSelect",
    mixins: [UsersSelect],
    components: { Select },

    props: {
      routeParam: {
        type: String,
        default: "id"
      },

      namespaceModule: {
        type: String,
        default: "expenseItems"
      }
    },

    methods: {
      async getCities (label = "", isLoading = false) {
        await this.getFiltersByName(this.namespaceModule, this.list, this.$route.query[this.routeParam] ?? this.value,
                                    this.routeParam, label, isLoading, this.filterOptions);
        return this.list;
      }
    }
  };
</script>

<style scoped>

</style>